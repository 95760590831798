import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, { debug: true, ignore_dnt: true });

let env_check = process.env.NODE_ENV === 'production';
// let env_check = true;

let actions = {
  identify: (id) => {
    // if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    // if (env_check) mixpanel.alias(id);
  },
  track: (name, props={}) => {
    // if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props={}) => {
      // if (env_check) mixpanel.people.set(props);
    },
    set_once: (props={}) => {
      // if (env_check) mixpanel.people.set_once(props);
    }
  },
};

export let Mixpanel = actions;
