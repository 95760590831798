import Head from 'next/head'
import Link from 'next/link'
import Image from 'next/image'
import Header from '@/components/landing-page/Header'
import { AuthLayout } from '@/components/AuthLayout'
import { Button } from '@/components/core/Button'
import TextField from '@/components/core/TextField'
import { useSession } from '@/hooks/useSession'
import { useState } from 'react'
import { useForm, register, Controller } from 'react-hook-form'
import logoChq from '@/images/logos/campaignhq-logo.svg'
import api from '@/api'
import { CatchPromise } from '@/utils/catch-promise'
import { showToast } from '@/utils/toastify'
import { Mixpanel } from '@/utils/mixpanel'
import { NextSeo } from 'next-seo'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUser } from '@/store/sessionSlice'

export default function Login() {
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    register,
    reset,
  } = useForm({ mode: 'onChange' })

  const { signIn } = useSession()


  const dispatch = useDispatch()
  const { validate } = useSession()
  const currentUser = useSelector((state) => state.sessions.currentUser)
  useEffect(() => {
    ;(async () => {
      await validate((user) => dispatch(setCurrentUser(user)))
    })()
    return () => {}
  }, [])

  async function onSubmit(data) {
    const [err, response] = await CatchPromise(
      signIn(
        {
          email: data.email,
          password: data.password,
        },
        '/get-started'
      )
    )

    if (response) {
      Mixpanel.identify(response.data.id)
      Mixpanel.people.set_once({
        first_name: response.data.firstName,
        last_name: response.data.lastName,
        email: response.data.email,
        company: response.data.company.name,
      })
      Mixpanel.track('signed_in')
    }
  }

  async function oauthUrl(provider) {
    let variant = null
    if(window.location.host.includes('www')) variant = 'www.'

    const [err, response] = await CatchPromise(
      api.auth.oauthUrl({
        provider,
        variant,
      })
    )

    if (response) {
      window.location.href = response.data.url
    }
  }

  return (
    <>
      <NextSeo
        title="CampaignHQ Login | Secure Access to Your Campaign Tools"
        description="Log in to CampaignHQ for instant access to your powerful campaign management tools. Secure, fast, and user-friendly. Sign in now!"
        canonical="https://campaignhq.co/login/"
        openGraph={{
          url: 'https://campaignhq.co/login/',
          title: 'CampaignHQ Login | Secure Access to Your Campaign Tools',
          description:
            'Log in to CampaignHQ for instant access to your powerful campaign management tools. Secure, fast, and user-friendly. Sign in now!',
          images: [
            {
              url: 'https://campaignhq-prod-public.s3.ap-southeast-1.amazonaws.com/ss-chq.png',
              width: 1200,
              height: 630,
              alt: 'CampaignHQ',
            },
          ],
        }}
        twitter={{
          handle: '@campaignhqco',
          site: '@campaignhqco',
          cardType: 'summary_large_image',
        }}
      />
      <Head>
        <title>Sign In - CampaignHQ</title>
      </Head>
      <Header user={currentUser} withNav={false} location="login"/>
      <AuthLayout>
        <div className="flex flex-col">
          {/* <Link href="/" aria-label="Home">
            <Image src={logoChq} alt="Logo" unoptimized className="mx-auto" />
          </Link> */}
          <div className="">
            <h2 className="text-xl font-semibold text-gray-900">
              Sign in to your account 👋
            </h2>
            <p className="text-sm text-gray-700 sm:hidden">
              Don’t have an account?{' '}
              <Link
                href="/signup"
                className="font-medium text-blue-600 hover:underline"
              >
                Sign up
              </Link>{' '}
              for a free trial.
            </p>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-5 grid grid-cols-1"
        >
          <TextField
            register={register}
            label="Email address"
            validations={{
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            id="email"
            error={errors?.email}
            name="email"
            className="mb-5"
          />
          <TextField
            label="Password"
            type="password"
            register={register}
            id="password"
            error={errors?.password}
            validations={{
              required: 'Password is required',
            }}
            name="password"
            className="mb-5"
          />
          <div className="flex items-center justify-between mb-5">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>

            <div className="text-right text-sm">
              <Link
                href="/reset-password"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </Link>
            </div>
          </div>
          <div>
            <Button
              type="submit"
              variant="solid"
              size="fluid"
              loading={isSubmitting}
              disabled={false}
              text="Login"
            ></Button>
          </div>
        </form>
        <div>
          <div className="relative mt-6">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-white px-6 text-gray-900">Or</span>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-4">
            <button
              onClick={() => oauthUrl('google')}
              className="flex w-full items-center justify-center gap-3 rounded-md border border-gray-800 bg-white px-4 py-2 text-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#FFC107"
                  d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                ></path>
                <path
                  fill="#FF3D00"
                  d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                ></path>
                <path
                  fill="#4CAF50"
                  d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                ></path>
                <path
                  fill="#1976D2"
                  d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                ></path>
              </svg>
              <span className="text-sm font-semibold">
                Login with Google
              </span>
            </button>

            {/* <a
              href="#"
              className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
            >
              <svg
                className="h-5 w-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-sm font-semibold leading-6">GitHub</span>
            </a> */}
          </div>
        </div>
      </AuthLayout>
    </>
  )
}
